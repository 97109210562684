import * as React from "react"
import Layout from "../../components/layout"
import Star from "../../components/star"
import '../../style.css'

const LaunchFactSheet = () => {

    const info={
            title:'The Swift Launch Fact Sheet',
            image:'swirl.png',
            page:'launchFactSheet',
            description:"The Swift Launch fact sheet is a four-page color brochure that describes the science of NASA's Swift Mission",
            internalText:["The Swift launch fact sheet is a four-page color brochure that describes the science of NASA's Swift mission, as well as providing tables that summarize the instrumental parameters and the follow-up team participants. It was produced in September 2004 for distribution at the Swift launch and was updated two years after launch, in November 2006."],
            pdf:{
              pdf:'launchFactsheet.pdf',
              accessible:'launchFactsheetDisability.pdf'
            }
    }

  return (
    <Layout>
        <div>
          <h1>{info.title}</h1>
          <Star/>
          <p>{info.internalText}</p>
          {info.pdf.accessible?
          <p>Check out the <a href={`/resourceLinks/${info.pdf.pdf}`} target='_blank' rel="noreferrer">PDF</a> and <a href={`/resourceLinks/${info.pdf.accessible}`} target='_blank' rel="noreferrer">Disability accessible PDF</a></p>
          :
          <p>Check out the <a href={`/resourceLinks/${info.pdf.pdf}`} target='_blank' rel="noreferrer">PDF</a></p>
          }        
        </div>
    </Layout>
  )
}

export default LaunchFactSheet